import React, { useEffect, useState } from "react";
import '../Header/Headst.css'
import '../../App.css'
import { HashLink as Link } from 'react-router-hash-link';
import { animateScroll as scroll } from "react-scroll";


export default function Head( {videoLabels, videoIds } ) {

    const [currentVideoId, setCurrentVideoId] = useState(videoIds[0])
    const [isNavVisible, setIsNavVisible] = useState(true);
    

    const getElementTop = (element) => {
        return element.getBoundingClientRect().top + window.scrollY;
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollBottom = window.innerHeight + window.scrollY + 50;
            

            if (scrollBottom >= document.documentElement.scrollHeight) {
                setIsNavVisible(false);
            }
            else {
                setIsNavVisible(true);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [videoIds, currentVideoId]);

    const navClick = () => {
        const currentIndex = videoIds.indexOf(currentVideoId);
        const nextIndex = (currentIndex + 1) % videoIds.length; 
        setCurrentVideoId(videoIds[nextIndex]);
        
        const nextElement = document.getElementById(videoIds[nextIndex]);
        const elementTop = getElementTop(nextElement);
        scroll.scrollTo(elementTop);
    }

    

    const scrollOffset = (element, offset) => {
        const elementPosition = element.offsetTop - offset;
        window.scroll({
            top: elementPosition,
            left: 0,
            behavior: "smooth"
        });
    };

    const mMouseOver = (element) => {
        element.target.classList.add('clsSamdi');
    };

    const mMouseOut = (element) => {
        element.target.classList.remove('clsSamdi');
    };

    const sMouseOver = (element) => {
        element.target.classList.remove('clsSpace');
    }

    const sMouseOut = (element) => {
        element.target.classList.add('clsSpace');
    }

    return (
        <header style={{ fontFamily: 'cafe24'}}>
            <img src="img/samdiLogo.png" id="idTitle" alt="Logo"/>

            {videoIds.map(id => (
               <Link
               to={`/#${id}`}
               scroll={element => scrollOffset(element, 20)}
               className="clsMove"
               onMouseOver={mMouseOver}
               onMouseOut={mMouseOut}
               >
                {videoLabels[id]}
               </Link>
            ))}
            <label 
            id="idSpace"
            className="clsSpace"
            style={{ fontFamily: 'pcpBold' }}
            onMouseOver={sMouseOver}
            onMouseOut={sMouseOut}
            > 스페이스 </label>

            {isNavVisible ? (
                    <img src="img/Nav.png" id="idNav" className="show" onClick={navClick} alt="Nav" />
                ) : (
                    <img src="img/Nav.png" id="idNav" className="hide" onClick={navClick} alt="Nav" />
            )}
        </header>
    )
}