import { React, useState, useRef } from "react";
import QHead from "../QHeader/QHeader";
import "./Questionst.css"

export default function Question() {
    const [isJoin, setIsJoin] = useState(false);
    const [isSamdi, setIsSamdi] = useState(false);

    const j = useRef(null);
    const js = useRef(null);
    const s = useRef(null);
    const ss = useRef(null);

    const joinClick = (j, js, s, ss) => {
        if (!isJoin) {
            setIsJoin(true);
            j.current.style.height = "30vw";
            s.current.style.top = "55vh";
            ss.current.style.top = "47vh";
            js.current.classList.add('show');
        }

        else {
            setIsJoin(false);
            j.current.style.height = "10vw";
            s.current.style.top = "50vh";
            ss.current.style.top = "42vh";
            js.current.classList.remove('show');
        }
    }

    const samdiClick = (s, ss) => { 
        if (!isSamdi) {
            setIsSamdi(true);
            s.current.style.height = "30vw";
            ss.current.classList.add('show');
        }

        else {
            setIsSamdi(false);
            s.current.style.height = "10vw";
            ss.current.classList.remove('show');
        }
    }


    return (
        <div>
            <QHead/>
            <div id="idQBackGround">
                <div id="idQText" style={{ fontFamily: 'taebaek' }}> 자주 묻는 질문 </div>
                <div
                    ref={j}
                    id="idQJoin" 
                    className="clsQQues" 
                    style={{ fontFamily: 'taebaek' }} 
                    onClick={() => joinClick(j, js, s, ss)}>
                    <br/>Q. &nbsp; <span className="clsSamdi">삼디</span>에 들어오려면 어떤 지식이 필요한가요?
                </div>
                <div ref={js} id="idQJoinSub" className="clsQSub" style={{ fontFamily: 'cafe24' }}>
                    선배들이 기초부터 가르쳐주므로 지식을 필수적으로 요구하진 않습니다. <br/>
                    미리 공부하고 싶다면 팀에 따라 필요한 지식을 공부해야합니다. <br/>
                    각각 블렌더, 유니티, Go나 C++등의 기초를 공부하시면 도움이 될 수 있습니다.
                </div>
                <div ref={s} id="idQSamdi" 
                className="clsQQues" 
                style={{ fontFamily: 'taebaek' }}   
                onClick={() => samdiClick(s, ss)}> 
                    <br/>Q. &nbsp; <span class="clsSamdi">삼디</span>에서는 개발이 아닌 모델링만 하나요?
                </div>
                <div ref={ss} id="idQSamdiSub" className="clsQSub" style={{ fontFamily: 'cafe24' }}>
                    삼디의 프로젝트는 3D 모델링과 게임 개발로 진행됩니다. <br/>
                    개발은 삼디 내부 스페이스에서 담당하며, 유니티와 게임서버를 다룹니다. <br/>
                    모델링팀의 경우 전업 모델러도 있으나 다른 개발 전공인 경우도 있습니다. <br/>
                </div>
            </div>
        </div>
        
    )
}