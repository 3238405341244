import { React, useRef, useState } from "react";
import '../Main/Mainst.css'
import '../../App.css'
import { HashLink as Link } from 'react-router-hash-link';

export default function Main( { id } ) {

    const [isJoin, setIsJoin] = useState(false);
    const [isSamdi, setIsSamdi] = useState(false);

    const j = useRef(null);
    const js = useRef(null);
    const s = useRef(null);
    const ss = useRef(null);

    const scrollOffset = (el, offset) => {
        const elementPosition = el.offsetTop - offset;
        window.scroll({
            top: elementPosition,
            left: 0,
            behavior: "smooth"
        });
    };

    const joinClick = (j, js, s, ss) => {
        if (!isJoin) {
            setIsJoin(true);
            j.current.style.height = "12vw";
            s.current.style.top = "262vw";
            ss.current.style.top = "266vw";
            js.current.classList.add('show');
        }

        else {
            setIsJoin(false);
            j.current.style.height = "4vw";
            s.current.style.top = "254vw";
            ss.current.style.top = "258vw";
            js.current.classList.remove('show');
        }
    }

    const samdiClick = (s, ss) => {
        if (!isSamdi) {
            setIsSamdi(true);
            s.current.style.height = "12vw";
            ss.current.classList.add('show');
        }

        else {
            setIsSamdi(false);
            s.current.style.height = "4vw";
            ss.current.classList.remove('show');
        }
    }

    const joinOver = (j, js, s, ss) => {
        if (isJoin) {
            return;
        }
        j.current.style.height = "12vw";
        s.current.style.top = "262vw";
        ss.current.style.top = "266vw";
        js.current.classList.add('show');
    }

    const joinOut = (j, js, s, ss) => {
        if (isJoin) {
            return;
        }
        j.current.style.height = "4vw";
        s.current.style.top = "254vw";
        ss.current.style.top = "258vw";
        js.current.classList.remove('show');
    }

    const samdiOver = (s, ss) => {
        if (isSamdi) {
            return;
        }
        s.current.style.height = "12vw";
        ss.current.classList.add('show');
    }

    const samdiOut = (s, ss) => {
        if (isSamdi) {
            return;
        }
        s.current.style.height = "4vw";
        ss.current.classList.remove('show');
    }

    return (
        <div id={id}>
            <video src="video/main.mp4" muted autoPlay loop playsInline id="idHome"/>
            <div id="idHomeText" style={{ fontFamily: 'taebaek' }}> 
                3D로 <span className="clsSamdi">이상</span>을 표현합니다 
            </div>
            <Link
               to={`#idSamdi`}
               scroll={el => scrollOffset(el, 20)}
               id="idHomeSubText"
               style={{ fontFamily: 'cafe24'}}>
                View more &gt;&gt;
            </Link>
            
            <video src="video/samdi.mp4" muted autoPlay loop playsInline id="idSamdi"/>
            <div id="idSamdiText" style={{ fontFamily: 'taebaek'}}> 3D 동아리 <img src="img/samdiLogo.png" className="clsLogo" alt="Samdi" /></div>
            <div id="idSamdiSubText" style={{ fontFamily: "cafe24"}}> <span className="clsSamdi">삼디</span>는 대구소프트웨어마이스터고의 3D 전문 동아리입니다. <br/>
            blender를 이용한 3D 모델링과 3D 프린팅, <br/>
            Unity를 활용한 게임 개발을 하고 있습니다. <br/>
            아이디어와 이상을 3D로 실현하며 우리들의 생활을 발전시켜 나가고 있습니다. <br/>
             </div>
            
            <video src="video/History.mp4" muted autoPlay playsInline id="idHistory"/>
            <div id="idHistoryText" style={{ fontFamily: 'taebaek' }}>
                <img src="img/samdiLogo.png" className="clsLogo" alt="Logo"/>의 역사를 소개합니다</div>
            <div id="idHistorySubText" style={{ fontFamily: 'cafe24'}}>
                <span className="clsSamdi">삼디</span>는 역사 깊은 전통 3D 동아리입니다. <br/>
                2018년 나인랩스로 창설되어 현재의 삼디로 명목을 이어오고 있습니다. <br/>
                2023년 스페이스와의 병합 이후 게임 개발도 병행하고 있습니다. <br/>
            </div>

            <video src="video/Join.mp4" muted autoPlay loop playsInline id="idJoin" />
            <div id="idJoinText" style={{ fontFamily: 'taebaek '}}> 여러분의 <span className="clsSamdi">아이디어</span>를 펼쳐보세요 </div>
            <div id="idJoinSubText" style={{ fontFamily: 'cafe24' }}>
                <span className="clsSamdi">삼디</span>는 여러분의 이상을 응원합니다. <br/>
                여러분의 아이디어를 실현할 수 있도록 돕고 싶습니다. <br/>
                당신은 어떤 작품을 만들고 싶나요? <br/>
                <span className="clsSamdi"> 지금 삼디에 지원하세요! </span>
            </div>
            <Link to="https://url.kr/de1xm8" id="idJoinSamdi" style={{ fontFamily: 'cafe24' }}>
                Join Samdi &gt;&gt;
            </Link>

            <img src="img/Ques_Background.png" id="idQues" alt="QuesBackground"/>
            <div id="idQuesText" style={{ fontFamily: 'taebaek' }}> 자주 묻는 질문 </div>
            <div 
             ref={j} 
             id="idQuesJoin" 
             className="clsQues" 
             style={{ fontFamily: 'taebaek' }} 
             onClick={() => joinClick(j, js, s, ss)}
             onMouseOver={() => joinOver(j, js, s, ss)}
             onMouseOut={() => joinOut(j, js, s, ss)}>
                <br/>Q. &nbsp; <span className="clsSamdi">삼디</span>에 들어오려면 어떤 지식이 필요한가요?
            </div>
            <div ref={js} id="idQuesJoinSub" className="clsQuesSub" style={{ fontFamily: 'cafe24' }}>
                선배들이 기초부터 가르쳐주므로 지식을 필수적으로 요구하진 않습니다. <br/>
                미리 공부하고 싶다면 팀에 따라 필요한 지식을 공부해야합니다. <br/>
                각각 블렌더, 유니티, Go나 C++등의 기초를 공부하시면 도움이 될 수 있습니다.
            </div>
            <div ref={s} id="idQuesSamdi" 
             className="clsQues" 
             style={{ fontFamily: 'taebaek' }}   
             onClick={() => samdiClick(s, ss)}
             onMouseOver={() => samdiOver(s, ss)}
             onMouseOut={() => samdiOut(s, ss)}> 
                <br/>Q. &nbsp; <span class="clsSamdi">삼디</span>에서는 개발이 아닌 모델링만 하나요?
            </div>
            <div ref={ss} id="idQuesSamdiSub" className="clsQuesSub" style={{ fontFamily: 'cafe24' }}>
                삼디의 프로젝트는 3D 모델링과 게임 개발로 진행됩니다. <br/>
                개발은 삼디 내부 스페이스에서 담당하며, 유니티와 게임서버를 다룹니다. <br/>
                모델링팀의 경우 전업 모델러도 있으나 다른 개발 전공인 경우도 있습니다. <br/>
            </div>
        </div>
        
    )
}