import React, { useEffect, useState } from "react";
import '../mHeader/mHeadst.css';
import '../../App.css';
import { HashLink as Link } from 'react-router-hash-link';

export default function QHead() {
    const [isClickMenu, setIsClickMenu] = useState(false);
    
    const sHeadClick = () => {
        if (!isClickMenu) {
            setIsClickMenu(true)
        }
        else {
            setIsClickMenu(false)
        }
    }

    useEffect(() => {
        if (isClickMenu) {
            document.body.style.overflowY = 'hidden';
            document.documentElement.style.overflowY = 'hidden';
        }
        else {
            document.body.style.overflowY = 'auto';
            document.documentElement.style.overflowY = 'auto';
        }
    }, [isClickMenu]);



    return (
        <header style={{ fontFamily: 'cafe24'}}>
            <Link to="/Samdi">
                <img src="/img/samdiLogo.png" id="idMTitle" alt="Logo"/>
            </Link>
            
            {isClickMenu ? (
                    <img src="/img/Cancel.png" id="idSideHead" onClick={sHeadClick} alt="Cancel"/>
                ) : (
                    <img src="/img/SideHeader.png" id="idSideHead" onClick={sHeadClick} alt="Menu"/>
            )}
            <div id="idMenuBackGround" className={isClickMenu ? 'mshow' : 'mhide'} onClick={sHeadClick}/>
            <div id="idSideMenu" className={isClickMenu ? 'open' : ''} style={{ fontFamily: 'cafe24'}}>
                <Link to="/Samdi">
                    <img src="/img/samdiLogo.png" id="idMenuLogo" alt="Logo"/>  
                </Link>
                <Link to="/Samdi" id="idMHHome" className="clsMenuText"> Home </Link>
                <Link to="/Samdi/History" id="idMHHistory" className="clsMenuText"> History </Link>
                <a href="https://url.kr/de1xm8" id="idMHJoin" className="clsMenuText"> Join </a>
                <Link to="/Samdi/Question" id="idMHQues" className="clsMenuText"> Question </Link>
                <div id="idMSpace" style={{ fontFamily: "pcpBold" }}> 스페이스 </div>
            </div>
        </header>
    )
}