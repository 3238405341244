import React from "react";

import Head from "../Header/Head";
import Main from "../Main/Main";

export default function SamdiView() {
    const videoLabels = { 
        'idHome' : 'Home',
        'idSamdi' : 'Samdi',
        'idHistory' : 'History',
        'idJoin' : 'Join',
        'idQues' : 'Question'
    };
    
    const videoIds = Object.keys(videoLabels);
    
    return ( 
        <div>
            <Head videoLabels={videoLabels} videoIds={videoIds}/>
            <Main id={videoIds}/>
        </div>
    );
}