import React from "react";
import QHead from "../QHeader/QHeader";

export default function History() {
    return (
        <div style={{width: "100vw", height: "100vh", backgroundColor: "rgb(58, 58, 58)"}}>
            <QHead/>
            <img 
                src="/img/History.png" 
                style={{ position: "Absolute", 
                top: "10vh", 
                width: "100%",
                height: "auto" }} 
                alt="History"/>
        </div>
    )
}