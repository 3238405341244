import React, { useEffect, useState } from "react";
import './mHeadst.css';
import '../../App.css';
import { HashLink as Link } from 'react-router-hash-link';
import { animateScroll as scroll } from "react-scroll";
import { useLocation } from "react-router-dom";

export default function MHead( {videoIds} ) {
    const [currentVideoId, setCurrentVideoId] = useState(videoIds[0])
    const [isNavVisible, setIsNavVisible] = useState(true);
    const [isClickMenu, setIsClickMenu] = useState(false);
    
    const location = useLocation();
    
    const getElementTop = (element) => {
        return element.getBoundingClientRect().top + window.scrollY;
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollBottom = window.innerHeight + window.scrollY + 100;
            

            if (scrollBottom >= document.documentElement.scrollHeight) {
                setIsNavVisible(false);
            }
            else {
                setIsNavVisible(true);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [videoIds, currentVideoId, location]);

    const navClick = () => {
        const currentIndex = videoIds.indexOf(currentVideoId);
        const nextIndex = (currentIndex + 1) % videoIds.length; 
        setCurrentVideoId(videoIds[nextIndex]);
        
        const nextElement = document.getElementById(videoIds[nextIndex]);
        const elementTop = getElementTop(nextElement);
        if (nextIndex === 2 || nextIndex === 0) {
            scroll.scrollTo(document.body.scrollHeight);
        }
        else {
            scroll.scrollTo(elementTop);
        }
    }

    const sHeadClick = () => {
        if (!isClickMenu) {
            setIsClickMenu(true)
        }
        else {
            setIsClickMenu(false)
        }
    }

    useEffect(() => {
        if (isClickMenu) {
            document.body.style.overflowY = 'hidden';
            document.documentElement.style.overflowY = 'hidden';
        }
        else {
            document.body.style.overflowY = 'auto';
            document.documentElement.style.overflowY = 'auto';
        }
    }, [isClickMenu]);



    return (
        <header style={{ fontFamily: 'cafe24'}}>
            <img src="img/samdiLogo.png" id="idMTitle" alt="Logo"/>
        
            {isClickMenu ? (
                    <img src="img/Cancel.png" id="idSideHead" onClick={sHeadClick} alt="Cancel"/>
                ) : (
                    <img src="img/SideHeader.png" id="idSideHead" onClick={sHeadClick} alt="Menu"/>
            )}
            <div id="idMenuBackGround" className={isClickMenu ? 'mshow' : 'mhide'} onClick={sHeadClick}/>
            <div id="idSideMenu" className={isClickMenu ? 'open' : ''} style={{ fontFamily: 'cafe24'}}>
                <img src="img/samdiLogo.png" id="idMenuLogo" alt="Logo"/>
                <Link to="/Samdi" id="idMHHome" className="clsMenuText"> Home </Link>
                <Link to="/Samdi/History" id="idMHHistory" className="clsMenuText"> History </Link>
                <a href="https://url.kr/de1xm8" id="idMHJoin" className="clsMenuText"> Join </a>
                <Link to="/Samdi/Question" id="idMHQues" className="clsMenuText"> Question </Link>
                <div id="idMSpace" style={{ fontFamily: "pcpBold" }}> 스페이스 </div>
            </div>
            

            {isNavVisible ? (
                    <img src="img/Nav.png" id="idMNav" className="mshow" onClick={navClick} alt="Nav" />
                ) : (
                    <img src="img/Nav.png" id="idMNav" className="mhide" onClick={navClick} alt="Nav" />
            )}
        </header>
    )
}