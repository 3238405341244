import './App.css';
import MobileSamdiView from './components/MobileSamdiView/MobileSamdiView'
import SamdiView from './components/SamdiView/SamdiView'
import History from './components/History/History';
import Question from './components/Question/Question'

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

function App() {
  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  let MainComponent = isMobile ? MobileSamdiView : SamdiView;

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/Samdi" replace />} />

        <Route path="/Samdi" element={<MainComponent />} />

        <Route path="/Samdi/History" element={<History />}/>
        <Route path="/Samdi/Question" element={<Question />}/>
      </Routes>
    </Router>
  );
}

export default App;
