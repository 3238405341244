import React from "react";

import MMain from '../mMain/mMain';
import MHeader from '../mHeader/mHead';

import '../../App.css';

export default function MobileSamdiView() {
    const videoIds = ['idMHome', 'idMSamdi', 'idMJoin'];

    return (
        <div>
            <MHeader videoIds={videoIds}/>
            <MMain id={videoIds}/>
        </div>
    )
}