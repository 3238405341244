import React from "react";

import '../../App.css';
import './mMainst.css';
import { HashLink as Link } from 'react-router-hash-link';

export default function MMain( {id} ) {
    return (
        <div id={id}>
            <div className="clsWrapper">
                <video src="video/main.mp4" muted autoPlay loop playsInline id="idMHome"/>
                <div id="idMHomeText" style={{ fontFamily: 'taebaek' }}> 
                    3D로 <span className="clsMSamdi">이상</span>을 표현합니다 
                </div>
                <Link
                to={`#idMSamdi`}
                scroll={el => el.scrollIntoView({ behavior: 'smooth' })}
                id="idMHomeSubText"
                style={{ fontFamily: 'cafe24'}}>
                    View more &gt;&gt;
                </Link>
            </div>
            <div className="clsWrapper">
                <video src="video/mSamdi.mp4" muted autoPlay loop playsInline id="idMSamdi"/>
                <div id="idMSamdiText" style={{ fontFamily: 'taebaek'}}> 3D 동아리 <img src="img/samdiLogo.png" className="clsMLogo" alt="Samdi" /></div>
                <div id="idMSamdiSubText" style={{ fontFamily: "cafe24"}}> <span className="clsMSamdi">삼디</span>는 대구소프트웨어마이스터고의 3D 전문 동아리입니다. <br/>
                    blender를 이용한 3D 모델링과 3D 프린팅, <br/>
                    Unity를 활용한 게임 개발을 하고 있습니다. <br/>
                    아이디어와 이상을 3D로 실현하며 우리들의 생활을 발전시켜 나가고 있습니다. <br/>
                </div>
            </div>
            <div className="clsWrapper">
                <video src="video/Join.mp4" muted autoPlay loop playsInline id="idMJoin" />
                <div id="idMJoinText" style={{ fontFamily: 'taebaek '}}> 여러분의 <span className="clsMSamdi">아이디어</span>를 펼쳐보세요 </div>
                <div id="idMJoinSubText" style={{ fontFamily: 'cafe24' }}>
                    <span className="clsMSamdi">삼디</span>는 여러분의 이상을 응원합니다. <br/>
                    여러분의 아이디어를 실현할 수 있도록 돕고 싶습니다. <br/>
                    당신은 어떤 작품을 만들고 싶나요? <br/>
                    <span className="clsMSamdi"> 지금 삼디에 지원하세요! </span>
                </div>
                <Link to="https://url.kr/de1xm8" id="idMJoinSamdi" style={{ fontFamily: 'cafe24' }}>
                    Join Samdi &gt;&gt;
                </Link>
            </div>
           
        </div>
    )
}